<template>
  <div class="app-members-add">
    <Module-Header title="Cafe Dialogue" :leading="true"></Module-Header>

    <Profile @save="save" ref="profile" :form="ruleForm" type="add"></Profile>
  </div>
</template>

<script>
import { addEateriesGroup } from '@/api/eateryGroup'
import Profile from './components/profile/profile.vue';

export default {
  components: { Profile },
  data() {
    return {
      ruleForm: {
        name_tc: '',
        name_en: '',
        logo: '',
        person: '',
        phone_number: '',
        email_address: '',
        zh_hant: '',
        en: '',
        username: '',
        password: ''
      },
    }
  }, 
  methods: {
    save(ruleForm) {
      let profile = this.$refs.profile,
      obj = {
        name: {
          zh_hant: ruleForm.name_tc,
          en: ruleForm.name_en,
        },
        logo: ruleForm['logo'],
        contact: {
          name: ruleForm['person'],
          phone_number: ruleForm['phone_number'],
          email_address: ruleForm['email_address'],
        },
        general: {
          zh_hant: ruleForm['zh_hant'],
          en: ruleForm['en']
        },
        "user": {
          "username": ruleForm.username,
          "password": ruleForm.password
        }
      }
      // console.log(obj)
      profile.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // console.log(obj)
          addEateriesGroup(obj).then(() => {
            this.$message.success('Successfully Saved')
            this.$router.go(-1)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>